<template>
    <div class="zc_dialog_box">
        <el-dialog title="设置封面" :visible.sync="dialogVisible" width="840px" :close-on-click-modal=false>
            <div class="cover_main">
                <div class="cover_title">系统封面</div>
                <div class="cover_list">
                    <div class="cover_item" :class="{ active: imageUrl == item }" v-for="(item, index) in list.slice(0, 12)"
                        :key="index" @click="changeAvatar(item)">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="cover_title">自定义封面</div>
                <div class="cover_upload_img">
                    <div class="img" :class="{ active: imageUrl == item }" v-for="(item, index) in list.slice(12)" :key="index"
                        @click="changeAvatar(item)">
                        <img :src="item" alt="">
                    </div>
                    <el-upload v-loading="loading" class="cover_upload" action="/member/utils/upload_file"
                        :headers="headers" accept="image/jpg,image/jpeg,image/png" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img src="@/assets/images/workben/upload.png" alt="">
                        <p>上传封面</p>
                    </el-upload>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            headers: {
                'Authorization': this.$store.state.token
            },
            dialogVisible: false,
            list: [
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/01.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/02.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/03.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/04.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/05.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/06.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/07.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/08.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/09.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/10.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/11.png',
                'http://yc-digital.oss-cn-shenzhen.aliyuncs.com/script/12.png',
            ],
            imageUrl: '',
            loading: false,
        };
    },
    created() {

    },
    methods: {
        changeAvatar(url) {
            this.imageUrl = url
        },
        confirm() {
            if (this.imageUrl == '') {
                this.$errMsg('请选择头像')
                return
            } else {
                this.$emit('editCover', this.imageUrl)
            }
            this.dialogVisible = false
        },
        open() {
            this.dialogVisible = true
        },
        handleAvatarSuccess(res) {
            // this.loading = true
            if (res.result.url) {
                // this.loading = false
                if (this.list.length == 13) {
                    this.list.pop()
                }
                this.imageUrl = res.result.url
                this.list.push(res.result.url)
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG/JEPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        close() {
            this.imageUr = ''
        },
    },
};
</script>

<style scoped lang="scss">
.cover_main {
    .cover_title {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
    }

    .cover_list {
        display: flex;
        flex-wrap: wrap;
        max-height: 300px;
        overflow-y: auto;
        margin-top: 14px;

        .cover_item {
            width: 120px;
            height: 140px;
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 4px;
            border: 2px solid #ffffff;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                border-radius: 2px;
            }
        }

        .cover_item.active {
            border: 2px solid #696efa;
        }
    }

    .cover_upload_img {
        padding: 14px 0;
        display: flex;
        flex-wrap: wrap;

        .img {
            width: 120px;
            height: 140px;
            margin: 0 10px 10px 0;
            border-radius: 4px;
            border: 2px solid #ffffff;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                border-radius: 2px;
            }
        }

        .img.active {
            border: 2px solid #696efa;
        }

        .cover_upload {
            width: 120px;
            height: 140px;
            background: #f5f5f5;
            border-radius: 4px;
            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
                margin: 45px 48px 0;
            }

            p {
                font-size: 14px;
                color: #2e4bf2;
                text-align: center;
                padding-top: 6px;
            }
        }
    }

    .cover_list::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 8px;
    }

    .cover_list::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px #d8d8d8;
        background: #d8d8d8;
    }

    .cover_list::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px #f8f8f8;
        border-radius: 5px;
        background: #f8f8f8;
    }
}</style>
