<!-- @Author: Yu_Bo -->
<template>
	<div class='book_loading zc_dialog_tip'>
		<el-dialog :visible.sync="dialogVisible" width="350px" :close-on-click-modal='false' :before-close='cancelBtn'>
		  <div class="loading_main">
        <div class="main_title">正在努力转换中，请稍等</div>
        <div class="main_icon">
          <img src="@/assets/images/workben/loading.png" alt="">
        </div>
        <div class="main_btn">
          <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        </div>
      </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      type:{
        type:String,
        default:''
      }
    },
		data() {
			return{
				dialogVisible:false
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开
      openDialog() {
        this.dialogVisible = true
      },
      // 关闭
      closeBtn(){
        this.dialogVisible = false
      },
      // 取消
      cancelBtn(){
        this.dialogVisible = false
        if(this.type=='video_link'){
          this.$emit('cancel')
        }
      },
    },
}
</script>

<style lang='scss' scoped>
.book_loading {
    ::v-deep .el-dialog {
        border-radius: 10px;
    }
    .loading_main {
        width: 100%;
        .main_title {
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
        .main_icon {
            width: 100%;
            padding: 22px 0;
            display: flex;
            justify-content: center;
            img {
                display: block;
                width: 50px;
                height: 50px;
                animation: loading-img 1s infinite ease-in-out;
            }
        }
        .main_btn {
            padding-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}
@keyframes loading-img {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
