<template>
    <div>
        <div>
            <el-dropdown trigger="click">
                <el-button class="btn" size="small" type="primary"><i class="el-icon-tickets"></i> 新增解析</el-button>
                <el-dropdown-menu slot='dropdown'>
                    <el-dropdown-item>
                        <div class="menu-item" @click="bookUrlBtn">
                            <img src="@/assets/images/workben/icon1.png" alt="">
                            <span>链接</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-upload action="" :accept="info.accept" :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadFile">
                            <div class="menu-item" @click="changeType('txt')">
                                <img src="@/assets/images/workben/icon2.png" alt="">
                                <span>TXT</span>
                            </div>
                        </el-upload>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-upload action="" :accept="info.accept" :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadFile">
                            <div class="menu-item" @click="changeType('pdf')">
                                <img src="@/assets/images/workben/icon3.png" alt="">
                                <span>PDF</span>
                            </div>
                        </el-upload>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-upload action="" :accept="info.accept" :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadFile">
                            <div class="menu-item" @click="changeType('word')">
                                <img src="@/assets/images/workben/icon4.png" alt="">
                                <span>word</span>
                            </div>
                        </el-upload>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-upload action="" :accept="info.accept" :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadFile">
                            <div class="menu-item" @click="changeType('ppt')">
                                <img src="@/assets/images/workben/icon5.png" alt="">
                                <span>PPT</span>
                            </div>
                        </el-upload>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-upload action="" :accept="info.accept" :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadVideo">
                            <div class="menu-item" @click="changeType('video')">
                                <img src="@/assets/images/workben/icon6.png" alt="">
                                <span>视频</span>
                            </div>
                        </el-upload>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-upload action="" :accept="info.accept" :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadAudio">
                            <div class="menu-item" @click="changeType('audio')">
                                <img src="@/assets/images/workben/icon7.png" alt="">
                                <span>音乐</span>
                            </div>
                        </el-upload>
                    </el-dropdown-item>
                    <div class="drop-bottom">
                        上传标准
                        <el-tooltip placement="right">
                            <div slot="content" class="list">
                                链接：请填写抖音、火山、快手、西瓜、小红书等视频链接<br />
                                TXT：请上传本地.txt文件，文件大小不超过10M<br />
                                PDF：请上传本地.pdf文件，文件大小不超过50M<br />
                                Word：请上传本地.doc或.docx文件，文件大小不超过50M<br />
                                PPT：请上传本地.ppt或.pptx文件，文件大小不超过50M<br />
                                视频：请上传本地mp4文件，视频大小不超过50M<br />
                                音乐：请上传本地wav或mp3文件，音频大小不超过50M<br />
                            </div>
                            <img src="@/assets/images/workben/icon8.png" alt="">
                        </el-tooltip>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <!-- 链接转台本 -->
        <book-url ref="urlBook" @analysisSuccess="analysisSuccess"></book-url>
        <book-loading ref="loadingBook"></book-loading>
    </div>
</template>

<script>
import BookUrl from '@/components/book_page/book_url.vue'//链接转台本
import BookLoading from '@/components/book_page/book_loading.vue'
export default {
    components: {
        BookUrl,
        BookLoading,
    },
    props: {

    },
    data() {
        return {
            acceptList: [
                {
                    accept: 'text/plain',
                    type: 'txt',
                    size: 10,
                    message: '上传文本只能是 .txt 格式!',
                },
                {
                    accept: 'application/pdf',
                    type: 'pdf',
                    size: 50,
                    message: '上传文件只能是 .pdf 格式!',
                },
                {
                    accept: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    type: 'word',
                    size: 50,
                    message: '上传文件只能是 .doc和.docx 格式!',
                },
                {
                    accept: 'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint',
                    type: 'ppt',
                    size: 50,
                    message: '上传文件只能是 .ppt和.pptx 格式!',
                },
                {
                    accept: 'video/mp4',
                    type: 'video',
                    size: 50,
                    message: '上传视频只能是 mp4 格式!',
                },
                {
                    accept: 'audio/x-mpeg,audio/x-wav,audio/mpeg,audio/wav',
                    type: 'audio',
                    size: 50,
                    message: '上传音频只能是 .wav和.mp3 格式!',
                },
            ],
            info: {
                accept: '',
                type: '',
                size: '',
                message: '',
            },
        };
    },
    methods: {
        // 音频转文本
        uploadAudio(res) {
            let newFile = new FormData()
            newFile.append('file', res.file)
            this.$refs.loadingBook.openDialog()
            this.$workbenApi.asrConvert(newFile).then(res => {
                this.$refs.loadingBook.closeBtn()
                if (res.code == 1000) {
                    this.$emit('analysisText', res.result.texts)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 视频转文本
        uploadVideo(res) {
            let newFile = new FormData()
            newFile.append('type', 1)
            newFile.append('file', res.file)
            console.log(newFile)
            this.$refs.loadingBook.openDialog()

            this.$workbenApi.videoConvert(newFile).then(res => {
                this.$refs.loadingBook.closeBtn()

                if (res.code == 1000) {
                    this.$emit('analysisText', res.result.texts)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // txt、word、pdf、ppt转文本
        uploadFile(res) {
            let newFile = new FormData()
            newFile.append('file', res.file)
            this.$refs.loadingBook.openDialog()

            this.$workbenApi.filesConvertToText(newFile).then(res => {
                this.$refs.loadingBook.closeBtn()

                if (res.code == 1000) {
                    this.$emit('analysisText', res.result.texts)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },

        // 切换上传类型
        changeType(type) {
            this.info = this.acceptList.find(ele => ele.type == type)
        },
        // 上传限制
        beforeUpload(file) {
            let arr = this.info.accept.split(',')
            const imgType = arr.includes(file.type)
            const isSize = file.size / 1024 / 1024 < this.info.size;
            if (!imgType) {
                this.$message.error(this.info.message);
                return false;
            }
            if (!isSize) {
                this.$message.error("上传文件大小不能超过 " + this.info.size + "M!");
                return false;
            }
        },
        // 解析链接成功
        analysisSuccess(val, type) {
            this.$emit('analysisSuccess', val, type)
        },
        // 链接转台本
        bookUrlBtn() {
            this.$refs.urlBook.openDialog()
        },
    },

};
</script>

<style scoped lang="scss">
.menu-item {
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 16px;
        height: 16px;
    }
    span {
        font-size: 14px;
        color: #333333;
        padding-left: 10px;
        display: inline-block;
        width: 40px;
    }
}
.drop-bottom {
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
    border-radius: 0px 0px 4px 4px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 12px;
        height: 12px;
        margin-left: 4px;
    }
}
.el-dropdown-menu {
    padding: 0;
}
.list {
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
    padding: 0 20px;
}
</style>
