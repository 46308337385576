<template>
    <div class="zc_dialog_box">
        <el-dialog title="解析有声台本" :visible.sync="dialogVisible" width="768px" :close-on-click-modal='false'>
            <div class="analysis_main">
                <div class="analysis_main_l">
                    <div class="analysis_title">请选择音色</div>
                    <div class="analysis_input">
                        <el-input v-model="search" prefix-icon="el-icon-search" placeholder="输入关键词搜索"></el-input>
                    </div>
                    <div class="audio_content">
                        <div class="audio_content_menu">
                            <div class="menu_li" :class="{active:current==index}" v-for="item,index in menuList" :key="index" @click="current=index">{{item.name}}</div>
                        </div>
                        <div class="audio_content_list">
                            <div class="list_li" v-for="item in list" :key="item.id">
                                <div v-if="item.children.length==1">
                                    <div class="card_item" v-for="ele in item.children" :key="ele.id" @click.stop="changeAudio(ele)" :class="{active:activeItem==item.id}">
                                        <div class="img"></div>
                                        <div class="info">
                                            <div class="info_t">
                                                <div class="name">{{item.name}}</div>
                                                <div class="play">
                                                    <img v-if="ele.play=='2'" src="@/assets/images/workben/play.png" @click="play(ele)" alt="">
                                                    <img v-if="ele.play=='1'" src="@/assets/images/workben/stop.png" @click="stop(ele)" alt="">
                                                </div>
                                            </div>
                                            <div class="info_b">
                                                <div class="label">英文</div>
                                                <div class="type">男声｜英语｜法式｜播音</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="card_item" @click.stop="item.show=!item.show">
                                        <div class="img"></div>
                                        <div class="info">
                                            <div class="info_t">
                                                <div class="name">{{item.name}}</div>
                                                <div class="play">
                                                    <img :class="{rotate:!item.show}" src="@/assets/images/workben/expand.png" alt="">
                                                </div>
                                            </div>
                                            <div class="info_b">
                                                <div class="label">英文</div>
                                                <div class="type">男声｜英语｜法式｜播音</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.show">
                                        <div v-for="ele in item.children" :key="ele.id" @click.stop="changeAudio(ele)" :class="{active:activeItem==ele.id}" class="card_item childer">
                                            <div class="img"></div>
                                            <div class="info">
                                                <div class="info_t">
                                                    <div class="name">{{item.name}}</div>
                                                    <div class="play">
                                                        <img v-if="ele.play=='2'" src="@/assets/images/workben/play.png" @click="play(ele)" alt="">
                                                        <img v-if="ele.play=='1'" src="@/assets/images/workben/stop.png" @click="stop(ele)" alt="">
                                                    </div>
                                                </div>
                                                <div class="info_b">
                                                    <div class="label">英文</div>
                                                    <div class="type">男声｜英语｜法式｜播音</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="analysis_main_r">
                    <div class="li">
                        <div class="text">语速</div>
                        <div class="input"><el-input size="small" type="number" v-model="num1" placeholder=""></el-input></div>
                        <div class="slider">
                            <el-slider v-model="num1" :min="-10" :max="20" :step="1" :show-tooltip="false"></el-slider>
                        </div>
                    </div>
                    <div class="li">
                        <div class="text">音量</div>
                        <div class="input"><el-input size="small" type="number" v-model="num2" placeholder=""></el-input></div>
                        <div class="slider">
                            <el-slider v-model="num2" :min="-10" :max="20" :step="1" :show-tooltip="false"></el-slider>
                        </div>
                    </div>
                    <div class="li">
                        <div class="text">语调</div>
                        <div class="input"><el-input size="small" type="number" v-model="num3" placeholder=""></el-input></div>
                        <div class="slider">
                            <el-slider v-model="num3" :min="-10" :max="20" :step="0.1" :show-tooltip="false"></el-slider>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            dialogVisible: false,
            search: '',
            num1: 0,
            num2: 0,
            num3: 0,
            menuList: [
                {
                    name: '最近使用',
                },
                {
                    name: '推荐',
                },
                {
                    name: '抖音爆款',
                },
                {
                    name: '超级主播',
                },

                {
                    name: '配音热门',
                },
                {
                    name: '影视解说',
                },
                {
                    name: '客服助手',
                },
                {
                    name: '情感语录',
                },
                {
                    name: '阅读听书',
                },
                {
                    name: '抖音爆款',
                },
                {
                    name: '超级主播',
                },
                {
                    name: '配音热门',
                },
                {
                    name: '影视解说',
                },
                {
                    name: '客服助手',
                },
                {
                    name: '情感语录',
                },
                {
                    name: '阅读听书',
                },
            ],
            current: 0,
            list: [
                {
                    name: '好妹妹',
                    id: 1,
                    show: false,
                    children: [
                        {
                            type: '通用',
                            id: 10,
                            play: '2',
                        },
                    ],
                },
                {
                    name: '好哥哥',
                    id: 2,
                    show: false,
                    children: [
                        {
                            type: '通用',
                            id: 20,
                            play: '2',
                        },
                        {
                            type: '愤怒',
                            id: 21,
                            play: '2',
                        },
                        {
                            type: '高兴',
                            id: 22,
                            play: '2',
                        },
                        {
                            type: '沉着',
                            id: 23,
                            play: '2',
                        },
                        {
                            type: '尴尬',
                            id: 24,
                            play: '2',
                        },
                    ],
                },
                {
                    name: '好妹妹',
                    id: 3,
                    show: false,
                    children: [
                        {
                            type: '通用',
                            play: '2',
                            id: 31,
                        },
                    ],
                },
                {
                    name: '好哥哥',
                    id: 4,
                    show: false,
                    children: [
                        {
                            type: '通用',
                            id: 40,
                            play: '2',
                        },
                        {
                            type: '愤怒',
                            id: 41,
                            play: '2',
                        },
                        {
                            type: '高兴',
                            id: 42,
                            play: '2',
                        },
                        {
                            type: '沉着',
                            id: 43,
                            play: '2',
                        },
                        {
                            type: '尴尬',
                            id: 44,
                            play: '2',
                        },
                    ],
                },
            ],
            activeItem: 1,
        };
    },
    computed: {

    },
    created() {

    },
    methods: {
        open() {
            this.dialogVisible = true
        },
        changeAudio(val) {
            this.activeItem = val.id
        },
        play(val) {
            this.list.forEach(ele => {
                ele.children.forEach(arr => {
                    if (arr.id == val.id) {
                        arr.play = '1'
                    } else {
                        arr.play = '2'
                    }
                })
            })
        },
        stop(val) {
            val.play = '2'
        },
    },
};
</script>

<style scoped lang="scss">
.analysis_main {
    display: flex;
    justify-content: space-between;
    .analysis_main_l {
        .analysis_title {
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            padding-bottom: 10px;
        }
        .analysis_input {
            width: 333px;
        }
        .audio_content {
            width: 333px;
            height: 371px;
            background: #f4f4f6;
            border-radius: 4px;
            border: 1px solid #d7d7d7;
            margin-top: 20px;
            display: flex;
            .audio_content_menu {
                width: 77px;
                height: 100%;
                background: #ffffff;
                overflow-y: auto;
                .menu_li {
                    width: 73px;
                    height: 40px;
                    border-left: 3px solid #ffffff;
                    font-size: 13px;
                    color: #333333;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                }
                .menu_li:hover {
                    color: #2e4bf2;
                }
                .menu_li.active {
                    background: rgba(45, 74, 241, 0.1);
                    border-left: 3px solid #2e4bf2;
                    color: #2e4bf2;
                }
            }
            .audio_content_list {
                width: 254px;
                height: 100%;
                padding-bottom: 10px;
                overflow-y: auto;
                .list_li {
                    .card_item.childer {
                        background: #e8e8e8;
                        border: 1px solid #e8e8e8;
                    }
                    .card_item {
                        cursor: pointer;
                        width: 225px;
                        height: 68px;
                        background: #ffffff;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        padding: 0 11px;
                        margin-top: 10px;
                        margin-left: 10px;
                        border: 1px solid #ffffff;
                        .img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background: #a77f7f;
                        }
                        .info {
                            margin-left: 12px;
                            .info_t {
                                display: flex;
                                align-items: center;
                                .name {
                                    font-size: 14px;
                                    color: #333333;
                                    width: 130px;
                                }
                                .play {
                                    width: 16px;
                                    height: 16px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .rotate {
                                    transform: rotate(-90deg);
                                    -webkit-transform: rotate(-90deg);
                                    transition-duration: 0.3s;
                                }
                            }
                            .info_b {
                                display: flex;
                                align-items: center;
                                padding-top: 6px;
                                .label {
                                    width: 34px;
                                    height: 18px;
                                    background: #782ef2;
                                    border-radius: 2px;
                                    font-size: 12px;
                                    color: #ffffff;
                                    line-height: 18px;
                                    text-align: center;
                                }
                                .type {
                                    width: 109px;
                                    font-size: 12px;
                                    color: #999999;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    -o-text-overflow: ellipsis;
                                    margin-left: 6px;
                                }
                            }
                        }
                    }
                    .card_item.active {
                        border: 1px solid #2d4af1;
                    }
                }
            }
        }
    }
    .analysis_main_r {
        width: 370px;
        height: 185px;
        background: #f4f4f6;
        border-radius: 2px;
        margin-top: 30px;
        padding: 20px;
        .li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .text {
                width: 28px;
                color: #666666;
                margin-right: 10px;
            }
            .input {
                width: 55px;
                margin-right: 14px;
            }
            .slider {
                width: 223px;
            }
        }
    }
}

.audio_content_menu::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.audio_content_menu::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px #636363;
    background: #636363;
}
.audio_content_menu::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px #ffffff;
    border-radius: 5px;
    background: #ffffff;
}
.audio_content_list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.audio_content_list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px #636363;
    background: #636363;
}
.audio_content_list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px #f4f4f6;
    border-radius: 5px;
    background: #f4f4f6;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
</style>
